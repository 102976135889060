/* eslint react/prop-types: 0 */
import React from "react"
import PropTypes from "prop-types"
import Masonry from "react-masonry-css"
import "lightbox-react/style.css"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { isMobile, isTablet } from "react-device-detect"

function ThumbNails({ images, handleOpen }) {
  const breakpointColumnsObj = {
    default: 3,
    1280: 3,
    1024: 2,
    768: 2,
    640: 1,
  }

  return (
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className="my-masonry-grid"
      columnClassName="my-masonry-grid_column"
    >
      {images.map((image, i) => (
        <div key={i}>
          <div className="px-2 py-1 md:px-2 md:py-2 relative">
            <div className="relative group">
              <div
                onClick={!isMobile || isTablet ? handleOpen(i) : null}
                className="cursor-pointer md:bg-black md:bg-opacity-0 md:group-hover:bg-opacity-100 relative "
              >
                <GatsbyImage
                  image={getImage(image.bildfil?.localFile.childImageSharp)}
                  className="md:group-hover:opacity-40  relative z-20  "
                  alt="Thumbnail"
                />
                <div className="hidden md:flex absolute top-0 left-0  w-full h-full items-center justify-center ">
                  <h3 className="text-white relative z-20 group-hover:opacity-100 transition-opacity duration-500 ease-in-out opacity-0   text-center p-4 text-sm md:text-xl font-medium ">
                    {image.caption}
                  </h3>
                </div>
              </div>
            </div>
            <div className="pt-1 font-bold text-center text-sm block md:hidden italic">
              {image.caption}
            </div>
          </div>
        </div>
      ))}
    </Masonry>
  )
}

ThumbNails.propTypes = {
  classes: PropTypes.object,
  images: PropTypes.array,
}
export default ThumbNails
