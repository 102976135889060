/* eslint react/prop-types: 0 */
import React from "react"
import "lightbox-react/style.css"
import LightboxReact from "lightbox-react"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Lightbox = ({
  images,
  selectedImage,
  handleClose,
  handlePrevRequest,
  handleNextRequest,
}) => {
  const array = []

  images.forEach(image =>
    array.push(
      <GatsbyImage
        image={getImage(image.bildfil.localFile.childImageSharp)}
        style={{ margin: "0 auto", maxHeight: "100vh", marginTop: "0px" }}
        imgStyle={{ objectFit: "contain", padding: "2vh 0", margin: "0 auto" }}
        className="w-full h-full"
        alt="Photo"
      />
    )
  )

  return (
    <LightboxReact
      enableZoom={false}
      clickOutsideToClose={true}
      mainSrc={array[selectedImage]}
      nextSrc={array[(selectedImage + 1) % array.length]}
      prevSrc={array[(selectedImage + array.length - 1) % images.length]}
      onCloseRequest={handleClose}
      onMovePrevRequest={handlePrevRequest(selectedImage, array.length)}
      onMoveNextRequest={handleNextRequest(selectedImage, array.length)}
    />
  )
}

export default Lightbox
